const axios = require('axios');


const authService = {

    authLogin(usuario) {
        return axios.post(`${process.env.VUE_APP_API_URL}api/auth/login/`, usuario ,{
            headers: {
            'x-token-clica': 'agenciaclica123'
            }
        });
    },
}

module.exports = authService;