<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="4">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h1>Ingresar</h1>
                  <CAlert
                      color="danger"
                      :show.sync="error"
                      closeButton
                    >
                      Al parecer las credenciales ingresadas son incorrectas
                    </CAlert> 
                  <p class="text-muted">Accede al panel de administración</p>
                  <CInput v-model="username"
                    placeholder="Usuario / Correo electrónico"
                    autocomplete="username email"
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput v-model="password"
                    placeholder="Contraseña"
                    type="password"
                    autocomplete="curent-password"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton v-on:click.prevent="triggerAuth()" color="primary" class="px-4">Iniciar sesión</CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
const authService = require('../../services/auth.service');
const Swal = require('sweetalert2');

export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      error: false
    }
  },
  methods: {
    triggerAuth: function(){
      let usuario = {
        username : this.username,
        password: this.password
      }
      authService.authLogin(usuario).then((response) => {
        this.error = false;

        let usuario = response.data.user;

        localStorage.setItem('user', JSON.stringify(usuario));

        this.$store.dispatch('saveUser', usuario);

        Swal.fire({
          icon: 'success',
          title: 'Inicio de sesión exitoso',
          text: "Ingresando a la aplicación...",
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          this.$router.push('/dashboard');
        });

        
        
      })
      .catch((error) => {
        this.error = true;
        console.log(error);

      })
    }
  }
}
</script>
